/***************************************************
*
* these css vars are copied from
* source/js/common/src/components/index.css
* We need to override them in order for them to point to the new DS colors
*
****************************************************/

:root.anchorage-dark {
  --color-marinaBlue-10: var(--colorRefShadesShade100);
  --color-marinaBlue-10-rgb: 0, 0, 0;
  --color-marinaBlue-20: var(--colorRefNeutralNeutral80);
  --color-marinaBlue-20-rgb: 34, 35, 37;
  --color-marinaBlue-30: var(--colorRefPrimaryPrimary70);
  --color-marinaBlue-30-rgb: 49, 50, 53;
  --color-marinaBlue-40: var(--colorRefPrimaryPrimary60);
  --color-marinaBlue-40-rgb: 56, 86, 168;
  --color-marinaBlue-50: var(--colorRefPrimaryPrimary50);
  --color-marinaBlue-50-rgb: 85, 128, 246;
  --color-marinaBlue-60: var(--colorRefPrimaryPrimary40);
  --color-marinaBlue-60-rgb: 144, 172, 249;
  --color-marinaBlue-70: var(--colorRefPrimaryPrimary30);
  --color-marinaBlue-70-rgb: 192, 208, 252;
  --color-marinaBlue-80: var(--colorRefShadesShade0);
  --color-marinaBlue-80-rgb: 255, 255, 255;
  --color-marinaBlue-90: var(--colorRefShadesShade0);
  --color-marinaBlue-90-rgb: 255, 255, 255;

  --color-basaltGray-10: var(--colorRefNeutralNeutral90);
  --color-basaltGray-10-rgb: 22, 23, 24;
  --color-basaltGray-20: var(--colorRefNeutralNeutral80);
  --color-basaltGray-20-rgb: 34, 35, 37;
  --color-basaltGray-30: var(--colorRefNeutralNeutral70);
  --color-basaltGray-30-rgb: 49, 50, 53;
  --color-basaltGray-40: var(--colorRefNeutralNeutral60);
  --color-basaltGray-40-rgb: 78, 80, 85;
  --color-basaltGray-50: var(--colorRefNeutralNeutral50);
  --color-basaltGray-50-rgb: 118, 120, 127;
  --color-basaltGray-60: var(--colorRefNeutralNeutral40);
  --color-basaltGray-60-rgb: 162, 164, 169;
  --color-basaltGray-70: var(--colorRefNeutralNeutral30);
  --color-basaltGray-70-rgb: 202, 203, 206;
  --color-basaltGray-80: var(--colorRefNeutralNeutral20);
  --color-basaltGray-80-rgb: 228, 229, 231;
  --color-basaltGray-90: var(--colorRefNeutralNeutral10);
  --color-basaltGray-90-rgb: 244, 245, 245;

  --color-seafoamTeal-10: var(--colorSysOceanTeal10);
  --color-seafoamTeal-10-rgb: 0, 10, 9;
  --color-seafoamTeal-20: var(--colorSysOceanTeal80);
  --color-seafoamTeal-20-rgb: 0, 31, 28;
  --color-seafoamTeal-30: var(--colorSysOceanTeal70);
  --color-seafoamTeal-30-rgb: 1, 70, 63;
  --color-seafoamTeal-40: var(--colorSysOceanTeal60);
  --color-seafoamTeal-40-rgb: 2, 121, 109;
  --color-seafoamTeal-50: var(--colorSysOceanTeal50);
  --color-seafoamTeal-50-rgb: 3, 191, 172;
  --color-seafoamTeal-60: var(--colorSysOceanTeal40);
  --color-seafoamTeal-60-rgb: 93, 213, 201;
  --color-seafoamTeal-70: var(--colorSysOceanTeal30);
  --color-seafoamTeal-70-rgb: 162, 230, 224;
  --color-seafoamTeal-80: var(--colorSysOceanTeal20);
  --color-seafoamTeal-80-rgb: 215, 244, 241;
  --color-seafoamTeal-90: var(--colorSysOceanTeal10);
  --color-seafoamTeal-90-rgb: 239, 250, 249;

  --color-clownfishOrange-10: var(--colorSysStarfishOrange90);
  --color-clownfishOrange-rgb-10: 48, 18, 8;
  --color-clownfishOrange-20: var(--colorSysStarfishOrange80);
  --color-clownfishOrange-rgb-20: 103, 36, 14;
  --color-clownfishOrange-30: var(--colorSysStarfishOrange70);
  --color-clownfishOrange-rgb-30: 152, 54, 22;
  --color-clownfishOrange-40: var(--colorSysStarfishOrange60);
  --color-clownfishOrange-rgb-40: 206, 73, 28;
  --color-clownfishOrange-50: var(--colorSysStarfishOrange50);
  --color-clownfishOrange-rgb-50: 255, 91, 36;
  --color-clownfishOrange-60: var(--colorSysStarfishOrange40);
  --color-clownfishOrange-rgb-60: 255, 125, 82;
  --color-clownfishOrange-70: var(--colorSysStarfishOrange70);
  --color-clownfishOrange-rgb-70: 255, 156, 122;
  --color-clownfishOrange-80: var(--colorSysStarfishOrange80);
  --color-clownfishOrange-rgb-80: 255, 221, 209;
  --color-clownfishOrange-90: var(--colorSysStarfishOrange90);
  --color-clownfishOrange-rgb-90: 255, 240, 235;

  --color-lobsterRed-10: var(--colorSysLobsterRed90);
  --color-lobsterRed-10-rgb: 39, 2, 5;
  --color-lobsterRed-20: var(--colorSysLobsterRed80);
  --color-lobsterRed-20-rgb: 66, 6, 11;
  --color-lobsterRed-30: var(--colorSysLobsterRed70);
  --color-lobsterRed-30-rgb: 93, 9, 16;
  --color-lobsterRed-40: var(--colorSysLobsterRed60);
  --color-lobsterRed-40-rgb: 147, 16, 27;
  --color-lobsterRed-50: var(--colorSysLobsterRed50);
  --color-lobsterRed-50-rgb: 213, 32, 47;
  --color-lobsterRed-60: var(--colorSysLobsterRed40);
  --color-lobsterRed-60-rgb: 232, 100, 111;
  --color-lobsterRed-70: var(--colorSysLobsterRed30);
  --color-lobsterRed-70-rgb: 241, 167, 173;
  --color-lobsterRed-80: var(--colorSysLobsterRed20);
  --color-lobsterRed-80-rgb: 247, 212, 215;
  --color-lobsterRed-90: var(--colorSysLobsterRed10);
  --color-lobsterRed-90-rgb: 252, 238, 239;

  --color-submarineYellow-10: var(--colorSysSunsetYellow90);
  --color-submarineYellow-10-rgb: 39, 28, 2;
  --color-submarineYellow-20: var(--colorSysSunsetYellow80);
  --color-submarineYellow-20-rgb: 97, 69, 5;
  --color-submarineYellow-30: var(--colorSysSunsetYellow70);
  --color-submarineYellow-30-rgb: 155, 111, 8;
  --color-submarineYellow-40: var(--colorSysSunsetYellow60);
  --color-submarineYellow-40-rgb: 208, 149, 11;
  --color-submarineYellow-50: var(--colorSysSunsetYellow50);
  --color-submarineYellow-50-rgb: 235, 177, 40;
  --color-submarineYellow-60: var(--colorSysSunsetYellow40);
  --color-submarineYellow-60-rgb: 240, 195, 92;
  --color-submarineYellow-70: var(--colorSysSunsetYellow30);
  --color-submarineYellow-70-rgb: 245, 216, 148;
  --color-submarineYellow-80: var(--colorSysSunsetYellow20);
  --color-submarineYellow-80-rgb: 253, 236, 196;
  --color-submarineYellow-90: var(--colorSysSunsetYellow10);
  --color-submarineYellow-90-rgb: 253, 248, 236;

  --color-algaeGreen-10: var(--colorSysAlgaeGreen90);
  --color-algaeGreen-10-rgb: 2, 25, 0;
  --color-algaeGreen-20: var(--colorSysAlgaeGreen80);
  --color-algaeGreen-20-rgb: 18, 54, 30;
  --color-algaeGreen-30: var(--colorSysAlgaeGreen70);
  --color-algaeGreen-30-rgb: 26, 77, 43;
  --color-algaeGreen-40: var(--colorSysAlgaeGreen60);
  --color-algaeGreen-40-rgb: 41, 122, 68;
  --color-algaeGreen-50: var(--colorSysAlgaeGreen50);
  --color-algaeGreen-50-rgb: 61, 184, 102;
  --color-algaeGreen-60: var(--colorSysAlgaeGreen40);
  --color-algaeGreen-60-rgb: 121, 210, 151;
  --color-algaeGreen-70: var(--colorSysAlgaeGreen30);
  --color-algaeGreen-70-rgb: 179, 230, 196;
  --color-algaeGreen-80: var(--colorSysAlgaeGreen20);
  --color-algaeGreen-80-rgb: 217, 242, 225;
  --color-algaeGreen-90: var(--colorSysAlgaeGreen10);
  --color-algaeGreen-90-rgb: 240, 250, 243;
  --color-white: var(--colorRefShadesShade0);
  --color-black: var(--colorRefShadesShade100);
  --button-text-primary: var(--colorCompButtonPrimarySolidOnContainerEnabled);
  --button-text-primary-hover: var(
    --colorCompButtonPrimarySolidOnContainerEnabled
  );
  --tab-unselected: var(--colorRefTextMuted);
  --tab-unselected-rgb: 192, 208, 252;
}

:root.porto-dark,
:root[theme='porto'] {
  --color-marinaBlue-10: var(--colorRefShadesShade100);
  --color-marinaBlue-10-rgb: 0, 0, 0;
  --color-marinaBlue-20: var(--colorRefNeutralNeutral80);
  --color-marinaBlue-20-rgb: 34, 35, 37;
  --color-marinaBlue-30: var(--colorRefPrimaryPrimary70);
  --color-marinaBlue-30-rgb: 49, 50, 53;
  --color-marinaBlue-40: var(--colorRefPrimaryPrimary60);
  --color-marinaBlue-40-rgb: 172, 150, 255;
  --color-marinaBlue-50: var(--colorRefPrimaryPrimary50);
  --color-marinaBlue-50-rgb: 172, 150, 255;
  --color-marinaBlue-60: var(--colorRefPrimaryPrimary40);
  --color-marinaBlue-60-rgb: 203, 189, 255;
  --color-marinaBlue-70: var(--colorRefPrimaryPrimary30);
  --color-marinaBlue-70-rgb: 227, 219, 255;
  --color-marinaBlue-80: var(--colorRefShadesShade0);
  --color-marinaBlue-80-rgb: 255, 255, 255;
  --color-marinaBlue-90: var(--colorRefShadesShade0);
  --color-marinaBlue-90-rgb: 255, 255, 255;

  --color-basaltGray-10: var(--colorRefNeutralNeutral90);
  --color-basaltGray-10-rgb: 20, 20, 20;
  --color-basaltGray-20: var(--colorRefNeutralNeutral80);
  --color-basaltGray-20-rgb: 34, 35, 37;
  --color-basaltGray-30: var(--colorRefNeutralNeutral70);
  --color-basaltGray-30-rgb: 49, 50, 53;
  --color-basaltGray-40: var(--colorRefNeutralNeutral60);
  --color-basaltGray-40-rgb: 78, 80, 85;
  --color-basaltGray-50: var(--colorRefNeutralNeutral50);
  --color-basaltGray-50-rgb: 118, 120, 127;
  --color-basaltGray-60: var(--colorRefNeutralNeutral40);
  --color-basaltGray-60-rgb: 162, 164, 169;
  --color-basaltGray-70: var(--colorRefNeutralNeutral30);
  --color-basaltGray-70-rgb: 202, 203, 206;
  --color-basaltGray-80: var(--colorRefNeutralNeutral20);
  --color-basaltGray-80-rgb: 228, 229, 231;
  --color-basaltGray-90: var(--colorRefNeutralNeutral10);
  --color-basaltGray-90-rgb: 244, 245, 245;

  --color-seafoamTeal-10: var(--colorSysOceanTeal10);
  --color-seafoamTeal-10-rgb: 0, 10, 9;
  --color-seafoamTeal-20: var(--colorSysOceanTeal80);
  --color-seafoamTeal-20-rgb: 0, 31, 28;
  --color-seafoamTeal-30: var(--colorSysOceanTeal70);
  --color-seafoamTeal-30-rgb: 1, 70, 63;
  --color-seafoamTeal-40: var(--colorSysOceanTeal60);
  --color-seafoamTeal-40-rgb: 2, 121, 109;
  --color-seafoamTeal-50: var(--colorSysOceanTeal50);
  --color-seafoamTeal-50-rgb: 3, 191, 172;
  --color-seafoamTeal-60: var(--colorSysOceanTeal40);
  --color-seafoamTeal-60-rgb: 93, 213, 201;
  --color-seafoamTeal-70: var(--colorSysOceanTeal30);
  --color-seafoamTeal-70-rgb: 162, 230, 224;
  --color-seafoamTeal-80: var(--colorSysOceanTeal20);
  --color-seafoamTeal-80-rgb: 215, 244, 241;
  --color-seafoamTeal-90: var(--colorSysOceanTeal10);
  --color-seafoamTeal-90-rgb: 239, 250, 249;

  --color-clownfishOrange-10: var(--colorSysStarfishOrange90);
  --color-clownfishOrange-rgb-10: 48, 18, 8;
  --color-clownfishOrange-20: var(--colorSysStarfishOrange80);
  --color-clownfishOrange-rgb-20: 103, 36, 14;
  --color-clownfishOrange-30: var(--colorSysStarfishOrange70);
  --color-clownfishOrange-rgb-30: 152, 54, 22;
  --color-clownfishOrange-40: var(--colorSysStarfishOrange60);
  --color-clownfishOrange-rgb-40: 206, 73, 28;
  --color-clownfishOrange-50: var(--colorSysStarfishOrange50);
  --color-clownfishOrange-rgb-50: 255, 91, 36;
  --color-clownfishOrange-60: var(--colorSysStarfishOrange40);
  --color-clownfishOrange-rgb-60: 255, 125, 82;
  --color-clownfishOrange-70: var(--colorSysStarfishOrange70);
  --color-clownfishOrange-rgb-70: 255, 156, 122;
  --color-clownfishOrange-80: var(--colorSysStarfishOrange80);
  --color-clownfishOrange-rgb-80: 255, 221, 209;
  --color-clownfishOrange-90: var(--colorSysStarfishOrange90);
  --color-clownfishOrange-rgb-90: 255, 240, 235;

  --color-lobsterRed-10: var(--colorSysLobsterRed90);
  --color-lobsterRed-10-rgb: 39, 2, 5;
  --color-lobsterRed-20: var(--colorSysLobsterRed80);
  --color-lobsterRed-20-rgb: 66, 6, 11;
  --color-lobsterRed-30: var(--colorSysLobsterRed70);
  --color-lobsterRed-30-rgb: 93, 9, 16;
  --color-lobsterRed-40: var(--colorSysLobsterRed60);
  --color-lobsterRed-40-rgb: 147, 16, 27;
  --color-lobsterRed-50: var(--colorSysLobsterRed50);
  --color-lobsterRed-50-rgb: 213, 32, 47;
  --color-lobsterRed-60: var(--colorSysLobsterRed40);
  --color-lobsterRed-60-rgb: 232, 100, 111;
  --color-lobsterRed-70: var(--colorSysLobsterRed30);
  --color-lobsterRed-70-rgb: 241, 167, 173;
  --color-lobsterRed-80: var(--colorSysLobsterRed20);
  --color-lobsterRed-80-rgb: 247, 212, 215;
  --color-lobsterRed-90: var(--colorSysLobsterRed10);
  --color-lobsterRed-90-rgb: 252, 238, 239;

  --color-submarineYellow-10: var(--colorSysSunsetYellow90);
  --color-submarineYellow-10-rgb: 39, 28, 2;
  --color-submarineYellow-20: var(--colorSysSunsetYellow80);
  --color-submarineYellow-20-rgb: 97, 69, 5;
  --color-submarineYellow-30: var(--colorSysSunsetYellow70);
  --color-submarineYellow-30-rgb: 155, 111, 8;
  --color-submarineYellow-40: var(--colorSysSunsetYellow60);
  --color-submarineYellow-40-rgb: 208, 149, 11;
  --color-submarineYellow-50: var(--colorSysSunsetYellow50);
  --color-submarineYellow-50-rgb: 235, 177, 40;
  --color-submarineYellow-60: var(--colorSysSunsetYellow40);
  --color-submarineYellow-60-rgb: 240, 195, 92;
  --color-submarineYellow-70: var(--colorSysSunsetYellow30);
  --color-submarineYellow-70-rgb: 245, 216, 148;
  --color-submarineYellow-80: var(--colorSysSunsetYellow20);
  --color-submarineYellow-80-rgb: 253, 236, 196;
  --color-submarineYellow-90: var(--colorSysSunsetYellow10);
  --color-submarineYellow-90-rgb: 253, 248, 236;

  --color-algaeGreen-10: var(--colorSysAlgaeGreen90);
  --color-algaeGreen-10-rgb: 2, 25, 0;
  --color-algaeGreen-20: var(--colorSysAlgaeGreen80);
  --color-algaeGreen-20-rgb: 18, 54, 30;
  --color-algaeGreen-30: var(--colorSysAlgaeGreen70);
  --color-algaeGreen-30-rgb: 26, 77, 43;
  --color-algaeGreen-40: var(--colorSysAlgaeGreen60);
  --color-algaeGreen-40-rgb: 41, 122, 68;
  --color-algaeGreen-50: var(--colorSysAlgaeGreen50);
  --color-algaeGreen-50-rgb: 61, 184, 102;
  --color-algaeGreen-60: var(--colorSysAlgaeGreen40);
  --color-algaeGreen-60-rgb: 121, 210, 151;
  --color-algaeGreen-70: var(--colorSysAlgaeGreen30);
  --color-algaeGreen-70-rgb: 179, 230, 196;
  --color-algaeGreen-80: var(--colorSysAlgaeGreen20);
  --color-algaeGreen-80-rgb: 217, 242, 225;
  --color-algaeGreen-90: var(--colorSysAlgaeGreen10);
  --color-algaeGreen-90-rgb: 240, 250, 243;
  --color-white: var(--colorRefShadesShade0);
  --color-black: var(--colorRefShadesShade100);
  --button-text-primary: var(--colorCompButtonPrimarySolidOnContainerEnabled);
  --button-text-primary-hover: var(--colorRefTextStrong);
  --tab-unselected: var(--colorRefTextMuted);
  --tab-unselected-rgb: 162, 164, 169;
  --error-page-background: #000000;
}
