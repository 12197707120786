.app {
  display: flex;

  /* just making it ready for mobile, if we need it. needs to be refined */
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
