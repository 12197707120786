/*
* copied from common
*/

@font-face {
  font-family: 'Akkurat Pro';
  font-weight: 700;
  font-style: normal;

  src:
    url('akkurat-pro/AkkuratPro-Bold.woff2') format('woff2'),
    url('akkurat-pro/AkkuratPro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Akkurat Pro';
  font-weight: 400;
  font-style: normal;

  src:
    url('akkurat-pro/AkkuratPro-Regular.woff2') format('woff2'),
    url('akkurat-pro/AkkuratPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Akkurat Pro';
  font-weight: 300;
  font-style: normal;

  src:
    url('akkurat-pro/AkkuratPro-Light.woff2') format('woff2'),
    url('akkurat-pro/AkkuratPro-Light.woff') format('woff');
}

@font-face {
  font-family: 'Akkurat Mono';
  font-weight: normal;
  font-style: normal;

  src:
    url('akkurat-mono/Akkurat-Mono.woff2') format('woff2'),
    url('akkurat-mono/Akkurat-Mono.woff') format('woff');
}
