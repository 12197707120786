.drawerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100vh;
}

.drawerMainTitleContainer {
  padding: 16px;
  border-bottom: 1px solid rgba(var(--color-marinaBlue-90-rgb), 0.3);
}

.drawerContentContainer {
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

.buttonDrawerContainer {
  text-align: right;
  padding: 16px;
  border-top: 1px solid rgba(var(--color-marinaBlue-90-rgb), 0.3);
}

.closeButtonContainer {
  position: absolute;
  top: 10px;
  right: 4px;
}

.list {
  list-style-type: disc;
  padding: 10px 0 !important;
  padding-left: 20px !important;
}

.drawerTextWeight {
  font-weight: 700;
}
