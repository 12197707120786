.container {
  display: flex;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .sideBannerContainer {
    display: none;
  }
}

@media only screen and (min-height: 1023px) and (orientation: portrait) {
  .sideBannerContainer {
    display: none;
  }
}

.drawerText {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.drawerTextAccordion {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawerTitle {
  font-size: 18px !important;
  font-weight: 500;
}

.drawerTextWeight {
  font-weight: 700;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.list {
  list-style-type: disc;
  padding: 10px 0 !important;
  padding-left: 20px !important;
}

.accordionContainer {
  background: var(--color-marinaBlue-10);
  border-radius: 4px;
  padding: 12px 12px 0;
}

.subText {
  color: var(--color-basaltGray-60);
  text-align: start;
  padding-bottom: 8px;
}

.orgDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
}
